* {
  font-family: "Karla", serif;
  background-color: #282c34;
  color: white;
}

.App {
  margin: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.App-logo {
  width: 50%;
}

.App-header {
  background-color: #282c34;
  color: #bb0a1e;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1%;
  font-size: xx-large;
}

.App-link {
  color: #bb0a1e;
  font-weight: bolder;
  text-decoration: underline;
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2%;
}

.video {
  justify-content: center;
  align-items: center;
}

.play-badge {
  width: 150px;
}

.Maximal-container,
.Overview-container,
.Tech-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.attribution {
  font-size: x-small;
}

.Nav-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Maximal-nav {
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: center;
}

.Maximal-button,
.Nav-item {
  border: 1px solid grey;
  margin: 5px;
  padding: 3px 5px;
  box-shadow: 2px 3px 3px rgb(65, 65, 65);
  color: rgb(158, 158, 158);
  text-decoration: none;
  list-style: none;
  font-size: medium;
}

.Maximal-button:hover,
.Nav-item:hover {
  border: 1px solid grey;
  color: rgb(158, 158, 158);
  background-color: #353b45;
  cursor: pointer;
}

.Maximal-button,
.Nav-item:focus {
  box-shadow: inset 2px 3px 1px rgb(65, 65, 65);
}

.social-badges,
.Tech-badges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.social-badge,
.Tech-badge {
  padding: 1%;
}

.Overview-para,
.Tech-para {
  margin: 6px;
  font-size: medium;
  text-align: justify;
}

.photo {
  height: 250px;
  border-radius: 50px;
  color: black;
  cursor: pointer;
  margin: 10px;
  position: relative;
  transition: flex 0.7s ease-in;
}

.bio-container {
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  align-items: center;
  justify-content: center;
}

.bio-h3 {
  margin: 8px;
  color: #fff;
  font-size: 24px;

  align-items: center;
  justify-content: center;
}

.bio-para {
  text-align: justify;
}

.social-badges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.social-badge {
  margin: 5px;
}

@media only screen and (min-width: 600px) {
  .App-logo {
    max-width: 700px;
  }

  .video-size {
    width: 560px;
    height: 315px;
  }

  .Maximal-container,
  .Overview-container,
  .Tech-container {
    max-width: 800px;
  }
}
